<template>
  <v-container class="fast-search">
    <CategoryTitle :category="category" :addContainer="false" />

    <div class="text-body-1 mb-2">
      {{ $t("fastSearch.searchSummary") }}
      <span v-for="(q, index) in query" :key="index">
        <router-link :to="{ name: 'Search', query: { q: q } }">{{
          q
        }}</router-link
        >{{
          index == query.length - 2
            ? $t("fastSearch.and")
            : index == query.length - 1
            ? "."
            : ",&nbsp;"
        }}</span
      >
    </div>
    <div class="search-result" v-for="(q, index) in query" :key="index">
      <div
        class="searched-word-header d-flex align-center flex-column flex-sm-row mb-5 mb-sm-2"
      >
        <div class="searched-word-title">
          <h3 v-html="$t('fastSearch.searchResult', [q])"></h3>
        </div>
        <v-spacer />
        <div>
          <router-link
            :to="{ name: 'Search', query: { q: q } }"
            :aria-label="$t('fastSearch.showAllProducts')"
            :title="$t('fastSearch.showAllProducts')"
          >
            {{ $t("fastSearch.showAllProducts") }}
          </router-link>
        </div>
      </div>
      <ProductSliderWrapper
        :query="q"
        :id="index"
        :counter="sliderCounter"
        :paginationClass="'swiper-pagination-' + index"
        :showBullets="true"
        :showArrows="true"
        :swiperOption="{
          breakpoints: {
            xs: { slidesPerView: 1 },
            sm: { slidesPerView: 2 },
            md: { slidesPerView: 3 },
            lg: { slidesPerView: 3 },
            xl: { slidesPerView: 4 }
          }
        }"
        @updateSliderWrapperViewable="updateSliderWrapperViewable"
      >
      </ProductSliderWrapper>
    </div>
  </v-container>
</template>
<style lang="scss">
.fast-search {
  .search-result {
    padding-bottom: 15px;
    margin-bottom: 18px;
    border-bottom: 1px solid var(--v-primary-lighten1);
  }
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import ProductSliderWrapper from "@/components/product/ProductSliderWrapper.vue";

import categoryMixins from "~/mixins/category";

export default {
  name: "FastSearch",
  components: {
    CategoryTitle,
    ProductSliderWrapper
  },
  mixins: [categoryMixins],
  data() {
    return {
      query: [],
      sliderCounter: 3
    };
  },
  methods: {
    getQueryParams() {
      this.query = this.$route.query.q;
    },
    updateSliderWrapperViewable(id) {
      this.sliderCounter = Math.max(id, this.sliderCounter);
    }
  },
  async created() {
    this.getQueryParams();
  },
  watch: {
    async "$route.query"() {
      this.getQueryParams();
    }
  }
};
</script>
