var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"fast-search"},[_c('CategoryTitle',{attrs:{"category":_vm.category,"addContainer":false}}),_c('div',{staticClass:"text-body-1 mb-2"},[_vm._v(" "+_vm._s(_vm.$t("fastSearch.searchSummary"))+" "),_vm._l((_vm.query),function(q,index){return _c('span',{key:index},[_c('router-link',{attrs:{"to":{ name: 'Search', query: { q: q } }}},[_vm._v(_vm._s(q))]),_vm._v(_vm._s(index == _vm.query.length - 2 ? _vm.$t("fastSearch.and") : index == _vm.query.length - 1 ? "." : ", "))],1)})],2),_vm._l((_vm.query),function(q,index){return _c('div',{key:index,staticClass:"search-result"},[_c('div',{staticClass:"searched-word-header d-flex align-center flex-column flex-sm-row mb-5 mb-sm-2"},[_c('div',{staticClass:"searched-word-title"},[_c('h3',{domProps:{"innerHTML":_vm._s(_vm.$t('fastSearch.searchResult', [q]))}})]),_c('v-spacer'),_c('div',[_c('router-link',{attrs:{"to":{ name: 'Search', query: { q: q } },"aria-label":_vm.$t('fastSearch.showAllProducts'),"title":_vm.$t('fastSearch.showAllProducts')}},[_vm._v(" "+_vm._s(_vm.$t("fastSearch.showAllProducts"))+" ")])],1)],1),_c('ProductSliderWrapper',{attrs:{"query":q,"id":index,"counter":_vm.sliderCounter,"paginationClass":'swiper-pagination-' + index,"showBullets":true,"showArrows":true,"swiperOption":{
        breakpoints: {
          xs: { slidesPerView: 1 },
          sm: { slidesPerView: 2 },
          md: { slidesPerView: 3 },
          lg: { slidesPerView: 3 },
          xl: { slidesPerView: 4 }
        }
      }},on:{"updateSliderWrapperViewable":_vm.updateSliderWrapperViewable}})],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }